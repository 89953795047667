import React, { useState, useEffect } from 'react';
import {Route, Routes } from 'react-router-dom';
import Register from './components/Register';
import Login from './components/Login';
import JobPosting from './components/JobPosting';
import JobDashboard from './components/JobDashboard';
import Home from './components/Home';
import { getUser, setUser, removeUser } from './utils/auth';
import './App.css';

function App() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const user = getUser();
    if (user) {
      setCurrentUser(user);
    }
  }, []);

  const handleLogin = (user) => {
    setUser(user);
    setCurrentUser(user);
  };

  const handleLogout = () => {
    removeUser();
    setCurrentUser(null);
  };

  return (
    
      <div className="App">
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/register' element={<Register />}/>
          <Route path="/login" element ={<Login onLogin={handleLogin} />}/>
          <Route path="/post-job" element={currentUser?<JobPosting user={currentUser}/>:<Login/>}/>
          <Route path="/dashboard" element={currentUser?<JobDashboard user={currentUser} onLogout={handleLogout}/>:<Login/>}/>
        </Routes>
      </div>
  
  );
}

export default App;
