import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { removeToken, removeUser } from '../utils/auth';
import './JobDashboard.css';
import {Link} from 'react-router-dom';

const JobDashboard = ({ user, onLogout }) => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      };
      const response = await axios.get('http://localhost:5000/api/jobs', config);
      setJobs(response.data);
    };
    fetchJobs();
  }, [user.token]);

  const handleLogout = () => {
    removeToken();
    removeUser();
    onLogout();
  };

  return (
    <div className="job-dashboard-container">
      <div className="user-details">
        <span>Welcome, {user.username}</span>
        <button onClick={handleLogout}>Logout</button>
      </div>
      <h2>Your Jobs</h2>
      <Link to='/post-job'><button>Post Job</button></Link>
      <ul>
        {jobs.map((job) => (
          <li key={job._id}>
            <h3>{job.title}</h3>
            <p>{job.description}</p>
            <p>Deadline: {new Date(job.deadline).toLocaleDateString()}</p>
            <p>Budget: ${job.budget}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default JobDashboard;
