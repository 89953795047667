import React, { useState } from 'react';
import axios from 'axios';
import './JobPosting.css';
import {useNavigate} from 'react-router-dom';

const JobPosting = ({ user }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [deadline, setDeadline] = useState('');
  const [budget, setBudget] = useState('');
  const navigate =useNavigate();

  const handleAddJob = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    };
    const response = await axios.post('http://localhost:5000/api/jobs', {
      title,
      description,
      deadline,
      budget
    }, config);

    if (response.data._id) {
      
      setTitle('');
      setDescription('');
      setDeadline('');
      setBudget('');
      navigate('/dashboard')
    } else {
      alert('Error posting job');
    }
  };

  return (
    <div className="job-posting-container">
      <h2>Post a Job</h2>
      <input
        type="text"
        placeholder="Job Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <textarea
        placeholder="Job Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      ></textarea>
      <input
        type="date"
        placeholder="Deadline"
        value={deadline}
        onChange={(e) => setDeadline(e.target.value)}
      />
      <input
        type="number"
        placeholder="Job Budget"
        value={budget}
        onChange={(e) => setBudget(e.target.value)}
      />
      <button onClick={handleAddJob}>Add Job</button>
    </div>
  );
};

export default JobPosting;
